import '../../Player.css';

import React,{ useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { PlayerContext } from '../../PlayerContext';
import DialogContainer from '../components/DialogContainer';
import {isChromeOnMacOS, isAndroid, isInstagramInAppBrowser, closeFullscreen } from '../../Utils';
import {Image} from 'react-native';
import { useAnimate } from 'framer-motion';

function ShareDialog(props){
    const { t } = useTranslation();
    const { onClose, opened } = props;
    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier");  
    const [showOtherWays, setShowOtherWays] = useState(false);

    const [scope, animate] = useAnimate();

    useEffect(() => {
        if (opened){   
                  
            const galleryOverlay = document.getElementById("dialog-background");
            if (galleryOverlay != null){                                  
                const animation = async () => {                       
                    animate(galleryOverlay, { opacity: 1}, {duration: 0.3});                    
                }
                
                animation();
            }                        
        }
    }, [opened]);

    function sendEmail(){
        const mailto = "mailto:" + "?subject=" + t("ShareTitle") + "&body=" + t("ShareText", {name: props.placeDetails != null ? (props.placeDetails.name !== "Holberton School Paris" ? props.placeDetails.name : "Holberton School") : t("ShareTextDefaultName")}) + " " + props.story.storyLink/* + "?utm_source=upreality&utm_medium=share"*/;    
        
        if (isChromeOnMacOS()){
            window.open(mailto, '_blank');
        }
        else {
            window.location.href = mailto;
        }
        onClose(false);
    }

    function shareOnFacebook(){
        
        const link = "https://www.facebook.com/dialog/share?app_id=407849446609669&display=popup&href=" + encodeURI(props.story.storyLink);

        if (isChromeOnMacOS()){
            window.open(link, '_blank');
        }
        else {
            window.location.href = link;
        }    
        onClose(false);
    }

    function copyToClipboard(){
        let shareText = t("ShareTitle") + "\n\n" + t("ShareText", {name: props.placeDetails != null ? (props.placeDetails.name !== "Holberton School Paris" ? props.placeDetails.name : "Holberton School") : t("ShareTextDefaultName")}) + "\n\n" + props.story.storyLink/* + "?utm_source=upreality&utm_medium=share"*/;
        navigator.clipboard.writeText(shareText);
        onClose(true);
    }

    return (<>
                {showOtherWays && <Image source="/images/other_share.png" onClick={() => {setShowOtherWays(false);}} style={{position: "absolute", top: 0, left: storyDimensions.containerWidth - 179, width: 179, height: 170, zIndex: 200}}/>}
                {<DialogContainer opened={opened} onClose={()=>{onClose();}} storyId={props.storyId} style={{opacity: 0}}>
                    <div onClick={(e)=>e.stopPropagation()}>
                    <DialogTitle style={{ userSelect: 'none', WebkitUserSelect: 'none', width: 240 * miniMultiplier }}>{t("ShareDialogTitle")}&nbsp;{t('ShareEmoji')}</DialogTitle>
                        <List>
                            <ListItemButton onClick={() => sendEmail()} key={"share_email"} style={{}}>
                                <ListItemIcon>
                                    <EmailIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24 }} />
                                </ListItemIcon>
                                <ListItemText className="booking-option" primary={t('ShareEmail')} />
                            </ListItemButton>    
                            <ListItemButton onClick={() => shareOnFacebook()} key={"share_facebook"} style={{}}>
                                <ListItemIcon>
                                    <ShareIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24 }} />
                                </ListItemIcon>
                                <ListItemText className="booking-option" primary={t('ShareFacebook')} />
                            </ListItemButton>  
                            <ListItemButton onClick={() =>{copyToClipboard()}} key={"share_clipboard"} style={{}}>
                                <ListItemIcon>
                                    <ContentCopyIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24 }} />
                                </ListItemIcon>
                                <ListItemText className="booking-option" primary={t('ShareCopy')} />
                            </ListItemButton> 

                            {isAndroid() && isInstagramInAppBrowser() && <ListItemButton onClick={() => {setShowOtherWays(true);closeFullscreen();}} key={"share_others"} style={{}}>
                                <ListItemIcon>
                                    <MoreVertIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24 }} />
                                </ListItemIcon>
                                <ListItemText className="booking-option" primary={t('ShareOther')} />
                            </ListItemButton>    }
                        </List>
                    </div>
                </DialogContainer> 
                }  
        </>      
    );
}

export default ShareDialog;